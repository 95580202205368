import '../../App.css'
import Layout from '../Layout/Layout'

function Biosamples () {
  return (
    <div>
      <Layout collection={'Biosamples'} />
    </div>
  )
}

export default Biosamples
