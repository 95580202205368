
import '../../App.css';

import Layout from '../Layout/Layout';

function GenomicVariations(props) {


  return (
    <div>
    <Layout collection={'Variant'}  />
    </div>
  )
}

export default GenomicVariations