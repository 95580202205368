import './Individuals.css';
import '../../App.css';

import Layout from '../Layout/Layout';

function Individuals(props) {


  return (
    <div>
    <Layout collection={'Individuals'}  />
    
    </div>
  )
}

export default Individuals