import React from 'react'

export default function ErrorPage() {
   return (
      <div>
         <h1>Oops! You seem to be lost.</h1>
         <p>Try another direction</p>
      </div>
   )
}
