import '../../App.css'
import Layout from '../Layout/Layout'

function Runs () {
  return (
    <div>
      <Layout collection={'Runs'} />
    </div>
  )
}

export default Runs
